/* eslint-disable no-console */

import { register } from 'register-service-worker'
import store from './store'

const urlB64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const saveSubscription = async subscription => {
  const authorizationToken = store.getters['Token/getAccessToken']
  const response = await fetch('/api/users/registerToken', {
    headers: {
      Authorization: `Bearer ${authorizationToken}`,
      'Content-Type': 'application/json'
    },
    mode: 'same-origin',
    cache: 'no-cache',
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify({
      token: subscription.toJSON()
    })
  })
  return response
}

const registerNotifications = async registration => {
  const permission = await Notification.requestPermission()

  if (permission !== 'granted') {
    return
  }

  const pubKey = 'BMQ8O30yX59IZs4f-ZaWI9C7VhAsW9ciduou4bjrY33KtlR5xik41prHsoY3HQlOzKFufeNGH6yCIXm9F_E8XTw'

  const applicationServerKey = urlB64ToUint8Array(pubKey)
  const options = { applicationServerKey, userVisibleOnly: true }
  const subscription = await registration.pushManager.subscribe(options)
  await saveSubscription(subscription)
}

if (process.env.NODE_ENV) {
  register(`${process.env.BASE_URL}sw.js`, {
    registrationOptions: {
      scope: '/'
    },
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    async registered (registration) {
      console.log('Service worker has been registered.')

      await registerNotifications(registration)
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
