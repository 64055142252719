<template>
  <v-app>
    <header>
      <div class="left">
        <img
          :src="menuIcon"
          alt="Menu Icon"
          class="menu-icon"
          @click.stop="navbar = !navbar"
        >
        <img
          alt="PWC Utilization Tracker"
          :src="pwcHeaderLogo"
        >
      </div>
      <div class="right">
        <img
          v-if="windowWidth <= 960"
          :src="errorReportIcon"
          alt="icon"
          @click="$router.push({ name: 'ErrorReport' })"
        >
        <div
          v-else
          class="name"
          @click="showUserDropdown = true"
        >
          <span>
            {{ getUserData.firstName[0] }}{{ getUserData.lastName[0] }}
          </span>
          <div
            v-if="showUserDropdown"
            v-click-outside="closeUserDropdown"
            class="user-dropdown"
          >
            <ul class="items">
              <li
                class="item"
                @click.stop="logout"
              >
                <img
                  :src="logoutDesktopIcon"
                  alt="Logout"
                >
                <span class="item-label">
                  {{ $t('header.logout') }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <breadcrumbs
      v-if="windowWidth > 960"
      class="breadcrumbs"
    />
    <transition name="slide">
      <aside
        v-if="navbar"
        v-click-outside="closeNavbar"
        class="navbar"
        :class="{opened: navbar}"
      >
        <div class="navbar-top">
          <div class="first-row">
            <img
              :src="closeMenuIcon"
              alt="Close menu"
              @click="closeNavbar"
            >
            <img
              alt="PWC Utilization Tracker"
              :src="pwcHeaderLogo"
            >
          </div>
          <ul v-if="routingSubset.length > 0">
            <li
              v-for="(route, i) in routingSubset"
              v-show="route.available.includes(getUserData.role)"
              :key="i"
              :class="{'active-route': ($route.name === route.where || ($route.path.includes('kpi') && route.where === 'Kpi'))}"
              @click.stop="routeTo(route.where)"
            >
              <component
                :is="route.icon"
                class="menu-icon"
              />
              <span
                class="route-name"
              >
                {{ route.name }}
              </span>
            </li>
          </ul>
        </div>
        <div class="navbar-bottom">
          <div
            class="logout-button"
            @click.stop="logout"
          >
            <img
              :src="logoutMobileIcon"
              alt="Logout"
            >
            <span class="logout-button-text">
              {{ $t('header.logout') }}
            </span>
          </div>
        </div>
      </aside>
    </transition>
    <v-main>
      <v-container
        class="fill-height pa-0 align-start container"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>
    <nav v-if="windowWidth <= 960 && routingSubset.length > 0">
      <ul class="bottom-menu">
        <li
          v-for="(route, i) in routingSubset"
          v-show="route.available.includes(getUserData.role)"
          :key="i"
          class="menu-item"
          :class="{'active-route': ($route.name === route.where || ($route.path.includes('kpi') && route.where === 'Kpi'))}"
          @click.stop="routeTo(route.where)"
        >
          <component
            :is="route.icon"
            class="menu-icon"
          />
          <span class="route-name">
            {{ route.nameMobile }}
          </span>
        </li>
      </ul>
    </nav>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import pwcHeaderLogo from '@/assets/pwc_header_logo.svg'
import menuIcon from '@/assets/icons/menu.svg'
import closeMenuIcon from '@/assets/icons/close.svg'
import firstMenuIcon from '@/components/AsideIcon/HomeIcon'
import secondMenuIcon from '@/components/AsideIcon/DangerIcon'
import thirdMenuIcon from '@/components/AsideIcon/ListIcon'
import fourthMenuIcon from '@/components/AsideIcon/AddUserIcon'
import fifthMenuIcon from '@/components/AsideIcon/BarIcon'
import errorReportIcon from '@/assets/icons/add_circle.svg'
import Breadcrumbs from '@/components/GlobalComponents/Breadcrumbs'
import logoutDesktopIcon from '@/assets/icons/logout_desktop.svg'
import logoutMobileIcon from '@/assets/icons/logout_mobile.svg'

export default {
  name: 'Complex',
  components: {
    firstMenuIcon,
    secondMenuIcon,
    thirdMenuIcon,
    fourthMenuIcon,
    fifthMenuIcon,
    Breadcrumbs
  },
  data () {
    return {
      pwcHeaderLogo,
      menuIcon,
      closeMenuIcon,
      langs: ['sk', 'en'],
      sessionStatistics: {},
      navbar: false,
      windowWidth: 0,
      errorReportIcon,
      showUserDropdown: false,
      logoutDesktopIcon,
      logoutMobileIcon
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData']),
    routingSubset () {
      return [
        {
          available: [
            'OPERATOR',
            'MAINTAINER',
            'HEAD_MAINTAINER',
            'ADMIN'
          ],
          where: 'Dashboard',
          icon: firstMenuIcon,
          name: this.$t('sidebar.firstRoute'),
          nameMobile: this.$t('navbar.firstRoute')
        },
        {
          available: [
            'OPERATOR',
            'MAINTAINER',
            'HEAD_MAINTAINER',
            'ADMIN'
          ],
          where: 'CurrentMachineErrors',
          icon: secondMenuIcon,
          name: this.$t('sidebar.secondRoute'),
          nameMobile: this.$t('navbar.secondRoute')
        },
        {
          available: [
            'OPERATOR',
            'MAINTAINER',
            'HEAD_MAINTAINER',
            'ADMIN'
          ],
          where: 'MachineErrorsHistory',
          icon: thirdMenuIcon,
          name: this.$t('sidebar.thirdRoute'),
          nameMobile: this.$t('navbar.thirdRoute')
        },
        {
          available: [
            'HEAD_MAINTAINER',
            'ADMIN'
          ],
          where: 'ErrorAssignMachineUsers',
          icon: fourthMenuIcon,
          name: this.$t('sidebar.fourthRoute'),
          nameMobile: this.$t('navbar.fourthRoute')
        },
        {
          available: [
            'HEAD_MAINTAINER',
            'ADMIN'
          ],
          where: 'Kpi',
          icon: fifthMenuIcon,
          name: this.$t('sidebar.fifthRoute'),
          nameMobile: this.$t('navbar.fifthRoute')
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  created () {
    this.getSessionStatistics()
    this.interval = setInterval(() => this.getSessionStatistics(), 30000)
  },
  beforeUnmount () {
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    routeTo (name) {
      if (this.$route.name !== name) {
        this.closeNavbar()
        this.$router.push({ name })
      }
    },
    async getSessionStatistics () {
      const { data } = await this.$axios.get('/session/statistics')
      this.sessionStatistics = data
    },
    logout () {
      this.$store.commit('Token/RESET')
      this.routeTo('Login')
    },
    goBack () {
      this.$router.go(-1)
    },
    closeNavbar () {
      this.navbar = false
    },
    closeUserDropdown () {
      this.showUserDropdown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

header {
  position: fixed;
  top: 0;
  background-color: $primary-red;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  z-index: 2;

  div.left {
    height: 24px;

    img.menu-icon {
      cursor: pointer;
      margin-right: 16px;
    }
  }

  div.right {
    display: flex;
    align-items: center;

    div.name {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid $primary-white;
      cursor: pointer;

      span {
        color: $primary-white;
        font-weight: 500;
      }

      div.user-dropdown {
        position: absolute;
        top: 48px;
        right: 0;
        width: 150px;
        padding: 8px 0;
        background-color: $primary-white;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05);

        ul.items {
          padding: 0;
          margin: 0;
          list-style-type: none;
          display: flex;
          flex-direction: column;
          width: 100%;

          li.item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            padding: 12px 25px;

            span.item-label {
              margin-left: 12px;
              color: $primary-black;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.breadcrumbs {
  position: fixed;
  top: 72px;
  z-index: 1;
}

aside.navbar {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: $primary-darkgrey;
  overflow-x: hidden;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.opened {
    width: 276px;
  }

  div.navbar-top {
    display: flex;
    flex-direction: column;

    div.first-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 32px;
    }

    ul {
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;

      li {
        cursor: pointer;
        padding: 16px 0;
        display: flex;
        align-items: center;

        &:hover {
          .menu-icon {
            fill: $primary-lightorange;
          }

          span.route-name {
            color: $primary-lightorange;
          }
        }

        span.route-name {
          color: $primary-lightgrey;
          font-size: 18px;
          line-height: 24px;
          margin-left: 16px;
        }

        &.active-route {
          .menu-icon {
            fill: $primary-lightorange;
          }

          span.route-name {
            color: $primary-lightorange;
            font-weight: 500;
          }
        }
      }
    }
  }

  div.navbar-bottom {
    width: 100%;

    div.logout-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 52px;
      background-color: $primary-darkgrey;
      border: 1px solid $primary-mediumgrey;

      span.logout-button-text {
        margin-left: 16px;
        font-weight: 500;
        color: $primary-white;
      }
    }
  }
}

.container {
  background-color: $background-color;
}

@media screen and (max-width: $mobile) {
  header {
    padding: 16px;
    height: 64px;
  }

  nav {
    display: block;
    position: fixed;
    bottom: 0;
    background-color: $primary-white;
    width: 100%;
    height: 87px;
    display: flex;
    align-items: flex-start;
    padding: 9px 0 32px 0;
    border-top: 1px solid $primary-lightgrey;

    ul.bottom-menu {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      width: 100%;

      li.menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75px;

        .menu-icon {
          fill: $primary-darkgrey;
        }

        span.route-name {
          color: $primary-darkgrey;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          margin-top: 4px;
        }

        &.active-route {
          .menu-icon {
            fill: $primary-lightorange;
          }

          span.route-name {
            color: $primary-lightorange;
          }
        }
      }
    }
  }
}
</style>
