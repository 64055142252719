function initialState () {
  return {
    accessToken: ''
  }
}

const state = initialState

const getters = {
  getAccessToken (state) {
    return state.accessToken
  }
}

const mutations = {
  RESET (state) {
    Object.assign(state, initialState())
  },
  ADD_ACCESS_TOKEN (state, accessToken) {
    state.accessToken = accessToken
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
