function initialState () {
  return {
    hallName: '', // Umiestnenie
    typeName: '', // Zariadenie
    machineName: '', // Stroj
    categoryName: '', // Typ poruchy
    flawlevel: '' // Zavaznost poruchy
  }
}

const state = initialState

const getters = {
  getHallName: state => state.hallName,
  getTypeName: state => state.typeName,
  getMachineName: state => state.machineName,
  getCategoryName: state => state.categoryName,
  getFlawlevel: state => state.flawlevel
}

const mutations = {
  RESET (state) {
    Object.assign(state, initialState())
  },
  SET_HALLNAME (state, hallName) {
    state.hallName = hallName
  },
  SET_TYPENAME (state, typeName) {
    state.typeName = typeName
  },
  SET_MACHINENAME (state, machineName) {
    state.machineName = machineName
  },
  SET_CATEGORYNAME (state, categoryName) {
    state.categoryName = categoryName
  },
  SET_FLAWLEVEL (state, flawlevel) {
    state.flawlevel = flawlevel
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
