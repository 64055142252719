<template>
  <nav
    v-if="
      $route.path.includes('error-report') ||
        $route.path.includes('machine/report') ||
        $route.path.includes('select-user') ||
        $route.path.includes('select-task')
    "
    class="breadcrumbs-nav"
  >
    <ul class="crumbs-list">
      <li
        class="crumbs-item"
        @click="$router.push({ name: 'Dashboard' })"
      >
        <img
          :src="BreadcrumbsHomeIcon"
          alt="Dashboard"
          class="home-icon"
        >
      </li>
      <img
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >

      <!-- TASK ASSIGN starts here -->
      <li
        v-if="$route.path.includes('machine/error-assign')"
        class="crumbs-item"
        @click="$router.push({ name: 'ErrorAssignMachineUsers' })"
      >
        <p>
          {{ $t('breadcrumbs.users') }}
        </p>
      </li>
      <img
        v-if="$route.path.includes('select-user') || $route.path.includes('select-task')"
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="$route.path.includes('machine/error-assign')"
        class="crumbs-item"
        @click="$router.push({ name: 'selectUser' })"
      >
        <p :class="{'active': $route.path.includes('select-user')}">
          {{ $t('breadcrumbs.selectUser') }}
        </p>
      </li>
      <img
        v-if="$route.path.includes('select-task')"
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="$route.path.includes('select-task') && $route.params.userId"
        class="crumbs-item"
        @click="$router.push({ name: 'selectTask' })"
      >
        <p :class="{'active': $route.path.includes('select-task')}">
          {{ $t('breadcrumbs.selectTask') }}
        </p>
      </li>
      <!-- TASK ASSIGN ends here -->
      <li
        v-if="
          $route.path.includes('error-report') ||
            $route.path.includes('machine/report')
        "
        class="crumbs-item"
        @click="$router.push({ name: 'ErrorReport' })"
      >
        <p :class="{'active': $route.path.includes('error-report')}">
          {{ $t('breadcrumbs.category') }}
        </p>
      </li>
      <img
        v-if="$route.path.includes('machine/report')"
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report')
        "
        class="crumbs-item"
        @click="$router.push({ name: 'ErrorReportMachine' })"
      >
        <p :class="{'active': $route.path.includes('machine/report') && !$route.params.hallId}">
          {{ $t('breadcrumbs.place') }}
        </p>
      </li>
      <img
        v-if="$route.path.includes('machine/report') && $route.params.hallId"
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') && $route.params.hallId
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectType' })"
      >
        <p :class="{'active': $route.path.includes('machine/report') && $route.params.hallId && !$route.params.type}">
          {{ $t('breadcrumbs.device') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <!-- STROJ -->
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'MACHINE'
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectMachine' })"
      >
        <p :class="{'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'MACHINE' && !$route.params.machineId}">
          {{ $t('breadcrumbs.machine') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'MACHINE' &&
            $route.params.machineId
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'MACHINE' &&
            $route.params.machineId
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectCategory' })"
      >
        <p
          :class="{
            'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'MACHINE' && $route.params.machineId && !$route.params.categoryId
          }"
        >
          {{ $t('breadcrumbs.type') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'MACHINE' &&
            $route.params.machineId &&
            $route.params.categoryId
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'MACHINE' &&
            $route.params.machineId &&
            $route.params.categoryId
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectFlawLevel' })"
      >
        <p
          :class="{
            'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'MACHINE' && $route.params.machineId && $route.params.categoryId && !$route.params.flawLevel
          }"
        >
          {{ $t('breadcrumbs.seriousness') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'MACHINE' &&
            $route.params.machineId &&
            $route.params.categoryId &&
            $route.params.flawLevel
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'MACHINE' &&
            $route.params.machineId &&
            $route.params.categoryId &&
            $route.params.flawLevel
        "
        class="crumbs-item"
        @click="$router.push({ name: 'addDescription' })"
      >
        <p
          :class="{
            'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'MACHINE' && $route.params.machineId && $route.params.categoryId && $route.params.flawLevel
          }"
        >
          {{ $t('breadcrumbs.description') }}
        </p>
      </li>
      <!-- VYBAVENIE BUDOVY -->
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'BUILDING_EQUIPMENT'
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectMachine' })"
      >
        <p :class="{'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type && !$route.params.machineId}">
          {{ $t('breadcrumbs.buildingEquipment') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'BUILDING_EQUIPMENT' &&
            $route.params.machineId
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'BUILDING_EQUIPMENT' &&
            $route.params.machineId
        "
        class="crumbs-item"
        @click="$router.push({ name: 'addDescription' })"
      >
        <p
          :class="{
            'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'BUILDING_EQUIPMENT' && $route.params.machineId
          }"
        >
          {{ $t('breadcrumbs.description') }}
        </p>
      </li>
      <!-- REZIJNE ZARIADENIA -->
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'OTHER_EQUIPMENT'
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectMachine' })"
      >
        <p :class="{'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'OTHER_EQUIPMENT' && !$route.params.machineId}">
          {{ $t('breadcrumbs.otherEquipment') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'OTHER_EQUIPMENT' &&
            $route.params.machineId
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'OTHER_EQUIPMENT' &&
            $route.params.machineId
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectCategory' })"
      >
        <p
          :class="{
            'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'OTHER_EQUIPMENT' && $route.params.machineId && !$route.params.categoryId
          }"
        >
          {{ $t('breadcrumbs.type') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'OTHER_EQUIPMENT' &&
            $route.params.machineId &&
            $route.params.categoryId
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'OTHER_EQUIPMENT' &&
            $route.params.machineId &&
            $route.params.categoryId
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectFlawLevel' })"
      >
        <p
          :class="{
            'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'OTHER_EQUIPMENT' && $route.params.machineId && $route.params.categoryId && !$route.params.flawLevel
          }"
        >
          {{ $t('breadcrumbs.seriousness') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'OTHER_EQUIPMENT' &&
            $route.params.machineId &&
            $route.params.categoryId &&
            $route.params.flawLevel
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'OTHER_EQUIPMENT' &&
            $route.params.machineId &&
            $route.params.categoryId &&
            $route.params.flawLevel
        "
        class="crumbs-item"
        @click="$router.push({ name: 'addDescription' })"
      >
        <p
          :class="{
            'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'OTHER_EQUIPMENT' && $route.params.machineId && $route.params.categoryId && $route.params.flawLevel
          }"
        >
          {{ $t('breadcrumbs.description') }}
        </p>
      </li>
      <!-- PRACA NA DIELNI -->
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'WORKSHOP'
        "
        class="crumbs-item"
        @click="$router.push({ name: 'selectMachine' })"
      >
        <p :class="{'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type && !$route.params.machineId}">
          {{ $t('breadcrumbs.workshop') }}
        </p>
      </li>
      <img
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'WORKSHOP' &&
            $route.params.machineId
        "
        :src="BreadcrumbsDividerIcon"
        alt="Divider"
        class="divider"
      >
      <li
        v-if="
          $route.path.includes('machine/report') &&
            $route.params.hallId &&
            $route.params.type === 'WORKSHOP' &&
            $route.params.machineId
        "
        class="crumbs-item"
        @click="$router.push({ name: 'addDescription' })"
      >
        <p
          :class="{
            'active': $route.path.includes('machine/report') && $route.params.hallId && $route.params.type === 'WORKSHOP' && $route.params.machineId
          }"
        >
          {{ $t('breadcrumbs.description') }}
        </p>
      </li>
    </ul>
  </nav>
</template>

<script>
import BreadcrumbsHomeIcon from '@/assets/icons/breadcrumbs_home.svg'
import BreadcrumbsDividerIcon from '@/assets/icons/breadcrumbs_divider.svg'

export default {
  name: 'Breadcrumbs',
  data () {
    return {
      BreadcrumbsHomeIcon,
      BreadcrumbsDividerIcon
    }
  }
}
</script>

<style lang="scss" scoped>
nav.breadcrumbs-nav {
  display: flex;
  align-items: center;
  padding: 16px 32px;
  width: 100%;
  height: 56px;
  background-color: $primary-white;

  ul.crumbs-list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    img.divider {
      margin: 0 21px;
    }

    li.crumbs-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        margin-bottom: 0;

        &.active {
          color: $primary-lightorange;
        }
      }
    }
  }
}
</style>
