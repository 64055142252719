const QuickStart = () => import('../views/it/QuickStart.vue')

export const itRouter = [
  {
    path: '/it/start',
    name: 'QuickStartIt',
    component: QuickStart,
    meta: {
      requiresAuth: true
    }
  }
]
