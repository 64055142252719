function initialState () {
  return {
    week: 1
  }
}

const state = initialState

const getters = {
  getWeek: state => state.week
}

const mutations = {
  RESET (state) {
    Object.assign(state, initialState())
  },
  SET_WEEK (state, week) {
    state.week = week
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
