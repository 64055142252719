import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import sk from 'vuetify/lib/locale/sk'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { sk },
    current: 'sk'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#D85604',
        secondary: '#E88D14',
        accent: '#7D7D7D',
        error: '#AD1B02',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#99ff21'
      }
    }
  }
})
