function initialState () {
  return {
    layout: 'complex'
  }
}

const state = initialState

const getters = {
  getLayout: state => state.layout
}

const mutations = {
  RESET (state) {
    Object.assign(state, initialState())
  },
  SET_LAYOUT (state, layout) {
    state.layout = layout
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
