function initialState () {
  return {
    firstName: '',
    lastName: '',
    id: '',
    badgeColor: ''
  }
}

const state = initialState

const getters = {
  getUserFirstName: state => state.firstName,
  getUserLastName: state => state.lastName,
  getUserId: state => state.id,
  getUserBadgeColor: state => state.badgeColor
}

const mutations = {
  RESET (state) {
    Object.assign(state, initialState())
  },
  SET_FIRSTNAME (state, firstName) {
    state.firstName = firstName
  },
  SET_LASTNAME (state, lastName) {
    state.lastName = lastName
  },
  SET_USERID (state, id) {
    state.id = id
  },
  SET_COLOR (state, badgeColor) {
    state.badgeColor = badgeColor
  }
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
