<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6465 23.2415C4.35365 23.2415 4.35365 23.2415 4.35365 23.2415C3.56645 23.2415 2.88965 22.5647 2.88965 21.6671C2.88965 2.21754 2.88965 2.21754 2.88965 2.21754C2.88965 1.43034 3.56645 0.75354 4.35365 0.75354C19.6465 0.75354 19.6465 0.75354 19.6465 0.75354C20.5441 0.75354 21.1105 1.43034 21.1105 2.21754C21.1105 21.6671 21.1105 21.6671 21.1105 21.6671C21.1105 22.5695 20.5441 23.2415 19.6465 23.2415ZM5.70245 11.9999C18.2977 11.9999 18.2977 11.9999 18.2977 11.9999C18.2977 9.18714 18.2977 9.18714 18.2977 9.18714C5.70245 9.18714 5.70245 9.18714 5.70245 9.18714V11.9999ZM5.70245 17.6207C18.2977 17.6207 18.2977 17.6207 18.2977 17.6207C18.2977 14.8079 18.2977 14.8079 18.2977 14.8079C5.70245 14.8079 5.70245 14.8079 5.70245 14.8079V17.6207ZM5.70245 6.37914C11.3232 6.37914 11.3232 6.37914 11.3232 6.37914C11.3232 3.56634 11.3232 3.56634 11.3232 3.56634C5.70245 3.56634 5.70245 3.56634 5.70245 3.56634V6.37914Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ListIcon'
}
</script>

<style lang="scss" scoped>
  svg {
    fill: $primary-lightgrey;
  }
</style>
