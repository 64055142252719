const QuickStart = () => import('../views/car/QuickStart.vue')
const ErrorReport = () => import('../views/car/ErrorReport.vue')
const ErrorDashboard = () => import('../views/car/ErrorDashboard.vue')
const ErrorDetail = () => import('../views/car/ErrorDetail.vue')
const TaskDashboard = () => import('../views/car/TaskDashboard')
const TaskDetail = () => import('../views/car/TaskDetail')
// const KPIPage = () => import('../views/car/KPIPage.vue')
const ErrorAssignDashboard = () => import('../views/car/ErrorAssign.vue')

export const carRouter = [
  {
    path: '/car/start',
    name: 'QuickStartCar',
    component: QuickStart,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/car/report',
    name: 'ErrorReportCar',
    component: ErrorReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/car/error-dashboard',
    name: 'ErrorDashboardCar',
    component: ErrorDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/car/error-detail/:id',
    name: 'ErrorDetailCar',
    component: ErrorDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/car/task-dashboard',
    name: 'TaskDashboardCar',
    component: TaskDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/car/task-detail/:id',
    name: 'TaskDetailCar',
    component: TaskDetail,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/car/performance',
  //   name: 'PerformancePageCar',
  //   component: KPIPage,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/car/error-assign',
    name: 'ErrorAssignCar',
    component: ErrorAssignDashboard,
    meta: {
      requiresAuth: true
    }
  }
]
