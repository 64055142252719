import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { machineRouter } from './machineRouter'
import { carRouter } from './carRouter'
import { itRouter } from './itRouter'
import { marketingRouter } from './marketingRouter'
const Login = () => import('../views/Login.vue')
const Dashboard = () => import('../views/Dashboard.vue')
const Kpi = () => import('../views/Kpi.vue')
const BasicKpi = () => import('@/components/Kpi/BasicKpi')
const MachinesKpi = () => import('@/components/Kpi/MachinesKpi')
const UsersKpi = () => import('@/components/Kpi/UsersKpi')
const ErrorReport = () => import('../views/ErrorReport.vue')
const NotFound = () => import('../views/NotFound.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/error-report',
    name: 'ErrorReport',
    component: ErrorReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/kpi',
    name: 'Kpi',
    component: Kpi,
    redirect: '/kpi/basic',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'basic',
        name: 'BasicKpi',
        component: BasicKpi
      },
      {
        path: 'machines',
        name: 'MachinesKpi',
        component: MachinesKpi
      },
      {
        path: 'users',
        name: 'UsersKpi',
        component: UsersKpi
      }
    ]
  },
  ...machineRouter,
  ...carRouter,
  ...itRouter,
  ...marketingRouter,
  {
    // catch all 404 - define at the very end
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.Token.accessToken === '') {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
