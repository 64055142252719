import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Layout from './modules/layout'
import Token from './modules/token'
import User from './modules/user'
import ErrorReport from './modules/errorReport'
import ErrorAssign from './modules/errorAssign'
import KpiDateRangeFilter from './modules/kpiDateRangeFilter'

Vue.use(Vuex)

const modules = {
  Layout,
  Token,
  User,
  ErrorReport,
  ErrorAssign,
  KpiDateRangeFilter
}

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: modules,
  plugins: [createPersistedState()]
})
