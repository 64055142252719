var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$route.path.includes('error-report') ||
      _vm.$route.path.includes('machine/report') ||
      _vm.$route.path.includes('select-user') ||
      _vm.$route.path.includes('select-task')
  )?_c('nav',{staticClass:"breadcrumbs-nav"},[_c('ul',{staticClass:"crumbs-list"},[_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'Dashboard' })}}},[_c('img',{staticClass:"home-icon",attrs:{"src":_vm.BreadcrumbsHomeIcon,"alt":"Dashboard"}})]),_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}),(_vm.$route.path.includes('machine/error-assign'))?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'ErrorAssignMachineUsers' })}}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.users'))+" ")])]):_vm._e(),(_vm.$route.path.includes('select-user') || _vm.$route.path.includes('select-task'))?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(_vm.$route.path.includes('machine/error-assign'))?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectUser' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('select-user')}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.selectUser'))+" ")])]):_vm._e(),(_vm.$route.path.includes('select-task'))?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(_vm.$route.path.includes('select-task') && _vm.$route.params.userId)?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectTask' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('select-task')}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.selectTask'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('error-report') ||
          _vm.$route.path.includes('machine/report')
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'ErrorReport' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('error-report')}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.category'))+" ")])]):_vm._e(),(_vm.$route.path.includes('machine/report'))?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report')
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'ErrorReportMachine' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('machine/report') && !_vm.$route.params.hallId}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.place'))+" ")])]):_vm._e(),(_vm.$route.path.includes('machine/report') && _vm.$route.params.hallId)?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectType' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && !_vm.$route.params.type}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.device'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'MACHINE'
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectMachine' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'MACHINE' && !_vm.$route.params.machineId}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.machine'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'MACHINE' &&
          _vm.$route.params.machineId
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'MACHINE' &&
          _vm.$route.params.machineId
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectCategory' })}}},[_c('p',{class:{
          'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'MACHINE' && _vm.$route.params.machineId && !_vm.$route.params.categoryId
        }},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.type'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'MACHINE' &&
          _vm.$route.params.machineId &&
          _vm.$route.params.categoryId
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'MACHINE' &&
          _vm.$route.params.machineId &&
          _vm.$route.params.categoryId
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectFlawLevel' })}}},[_c('p',{class:{
          'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'MACHINE' && _vm.$route.params.machineId && _vm.$route.params.categoryId && !_vm.$route.params.flawLevel
        }},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.seriousness'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'MACHINE' &&
          _vm.$route.params.machineId &&
          _vm.$route.params.categoryId &&
          _vm.$route.params.flawLevel
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'MACHINE' &&
          _vm.$route.params.machineId &&
          _vm.$route.params.categoryId &&
          _vm.$route.params.flawLevel
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'addDescription' })}}},[_c('p',{class:{
          'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'MACHINE' && _vm.$route.params.machineId && _vm.$route.params.categoryId && _vm.$route.params.flawLevel
        }},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.description'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'BUILDING_EQUIPMENT'
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectMachine' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type && !_vm.$route.params.machineId}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.buildingEquipment'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'BUILDING_EQUIPMENT' &&
          _vm.$route.params.machineId
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'BUILDING_EQUIPMENT' &&
          _vm.$route.params.machineId
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'addDescription' })}}},[_c('p',{class:{
          'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'BUILDING_EQUIPMENT' && _vm.$route.params.machineId
        }},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.description'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'OTHER_EQUIPMENT'
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectMachine' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'OTHER_EQUIPMENT' && !_vm.$route.params.machineId}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.otherEquipment'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'OTHER_EQUIPMENT' &&
          _vm.$route.params.machineId
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'OTHER_EQUIPMENT' &&
          _vm.$route.params.machineId
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectCategory' })}}},[_c('p',{class:{
          'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'OTHER_EQUIPMENT' && _vm.$route.params.machineId && !_vm.$route.params.categoryId
        }},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.type'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'OTHER_EQUIPMENT' &&
          _vm.$route.params.machineId &&
          _vm.$route.params.categoryId
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'OTHER_EQUIPMENT' &&
          _vm.$route.params.machineId &&
          _vm.$route.params.categoryId
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectFlawLevel' })}}},[_c('p',{class:{
          'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'OTHER_EQUIPMENT' && _vm.$route.params.machineId && _vm.$route.params.categoryId && !_vm.$route.params.flawLevel
        }},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.seriousness'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'OTHER_EQUIPMENT' &&
          _vm.$route.params.machineId &&
          _vm.$route.params.categoryId &&
          _vm.$route.params.flawLevel
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'OTHER_EQUIPMENT' &&
          _vm.$route.params.machineId &&
          _vm.$route.params.categoryId &&
          _vm.$route.params.flawLevel
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'addDescription' })}}},[_c('p',{class:{
          'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'OTHER_EQUIPMENT' && _vm.$route.params.machineId && _vm.$route.params.categoryId && _vm.$route.params.flawLevel
        }},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.description'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'WORKSHOP'
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'selectMachine' })}}},[_c('p',{class:{'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type && !_vm.$route.params.machineId}},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.workshop'))+" ")])]):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'WORKSHOP' &&
          _vm.$route.params.machineId
      )?_c('img',{staticClass:"divider",attrs:{"src":_vm.BreadcrumbsDividerIcon,"alt":"Divider"}}):_vm._e(),(
        _vm.$route.path.includes('machine/report') &&
          _vm.$route.params.hallId &&
          _vm.$route.params.type === 'WORKSHOP' &&
          _vm.$route.params.machineId
      )?_c('li',{staticClass:"crumbs-item",on:{"click":function($event){return _vm.$router.push({ name: 'addDescription' })}}},[_c('p',{class:{
          'active': _vm.$route.path.includes('machine/report') && _vm.$route.params.hallId && _vm.$route.params.type === 'WORKSHOP' && _vm.$route.params.machineId
        }},[_vm._v(" "+_vm._s(_vm.$t('breadcrumbs.description'))+" ")])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }