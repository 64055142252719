<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.8 21.1035V14.3976H14.2V21.1035H19.7V12.1623H23L12 2.10352L1 12.1623H4.3V21.1035H9.8Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon'
}
</script>

<style lang="scss" scoped>
  svg {
    fill: $primary-lightgrey;
  }
</style>
