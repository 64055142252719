const QuickStart = () => import('../views/machines/QuickStart.vue')
const ErrorReport = () => import('../views/machines/ErrorReport.vue')
const CurrentMachineErrors = () => import('../views/machines/CurrentMachineErrors.vue')
const MachineErrorsHistory = () => import('../views/machines/MachineErrorsHistory.vue')
const ErrorDetail = () => import('../views/machines/ErrorDetail.vue')
const TaskDashboard = () => import('../views/machines/TaskDashboard')
const TaskDetail = () => import('../views/machines/TaskDetail')
const ErrorAssignMachine = () => import('../views/machines/ErrorAssign.vue')
const ErrorAssignMachineUsers = () => import('@/components/Machines/ErrorAssignMachine/Users')
const ErrorAssignMachineSelectUser = () => import('@/components/Machines/ErrorAssignMachine/SelectUser')
const ErrorAssignMachineSelectTask = () => import('@/components/Machines/ErrorAssignMachine/SelectTask')
const HallSelect = () => import('@/components/Machines/ErrorReport/HallDecision')
const SelectType = () => import('@/components/Machines/ErrorReport/ErrorType')
const SelectMachine = () => import('@/components/Machines/ErrorReport/MachineDecision')
const SelectErrorCategory = () => import('@/components/Machines/ErrorReport/ErrorCategoryDecision')
const SelectFlawLevel = () => import('@/components/Machines/ErrorReport/FlawLevelDecision')
const AddDescription = () => import('@/components/Machines/ErrorReport/AddDescription')
const SuccessInfo = () => import('@/components/Machines/ErrorReport/SuccessInfo')

export const machineRouter = [
  {
    // old
    path: '/machine/start',
    name: 'QuickStartMachine',
    component: QuickStart,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/machine/report',
    component: ErrorReport,
    children: [
      {
        name: 'ErrorReportMachine',
        path: '',
        component: HallSelect
      }, {
        name: 'selectType',
        path: ':hallId',
        component: SelectType
      }, {
        name: 'successInfo',
        path: 'done',
        component: SuccessInfo
      }, {
        name: 'selectMachine',
        path: ':hallId/:type',
        component: SelectMachine
      }, {
        name: 'selectCategory',
        path: ':hallId/:type/:machineId',
        component: SelectErrorCategory
      }, {
        name: 'selectFlawLevel',
        path: ':hallId/:type/:machineId/:categoryId',
        component: SelectFlawLevel
      }, {
        name: 'addDescription',
        path: ':hallId/:type/:machineId/:categoryId/:flawLevel',
        component: AddDescription
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/machine/current-errors',
    name: 'CurrentMachineErrors',
    component: CurrentMachineErrors,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/machine/errors-history',
    name: 'MachineErrorsHistory',
    component: MachineErrorsHistory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/machine/error-detail/:id',
    name: 'ErrorDetailMachine',
    component: ErrorDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    // old
    path: '/machine/task-dashboard',
    name: 'TaskDashboardMachine',
    component: TaskDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    // old
    path: '/machine/task-detail/:id',
    name: 'TaskDetailMachine',
    component: TaskDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/machine/error-assign',
    component: ErrorAssignMachine,
    children: [
      {
        name: 'ErrorAssignMachineUsers',
        path: '',
        component: ErrorAssignMachineUsers
      }, {
        name: 'selectUser',
        path: 'select-user',
        component: ErrorAssignMachineSelectUser
      }, {
        name: 'selectTask',
        path: ':userId/select-task',
        component: ErrorAssignMachineSelectTask
      }
    ],
    meta: {
      requiresAuth: true
    }
  }
  // {
  //   path: 'machine/error-assign/select-user',
  //   name: 'ErrorAssignMachineSelectUser',
  //   component: ErrorAssignMachineUser
  // }
]
