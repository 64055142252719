function initialState () {
  return {
    userData: {}
  }
}

const state = initialState

const getters = {
  getUserData (state) {
    return state.userData
  }
}

const mutations = {
  RESET (state) {
    Object.assign(state, initialState())
  },
  CHANGE_USER_DATA (state, userData) {
    state.userData = { ...state.userData, ...userData }
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
