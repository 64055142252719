<template>
  <component :is="getLayout" />
</template>

<script>
import { mapGetters } from 'vuex'
import Complex from '@/layouts/Complex'
const Simple = () => import('@/layouts/Simple')

window._mfq = window._mfq || []
const mf = document.createElement('script')
mf.type = 'text/javascript'
mf.defer = true
mf.src = '//cdn.mouseflow.com/projects/4e9f0155-23e0-4351-a049-97f10cee6dc5.js'
document.getElementsByTagName('head')[0].appendChild(mf)

export default {
  name: 'App',
  components: {
    Complex,
    Simple
  },
  computed: {
    ...mapGetters('Layout', ['getLayout'])
  }
}
</script>
