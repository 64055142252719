<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.4 3.19764C2.76 5.28682 1 8.36635 1 11.9976C1 15.5158 2.76 18.7085 5.4 20.6845C10.4624 11.9976 10.4624 11.9976 10.4624 11.9976L5.4 3.19764ZM23 10.5687C22.3376 5.17835 17.72 1 12.1088 1C10.5712 1 9.02882 1.33012 7.70882 1.88189C12.88 10.5687 12.88 10.5687 12.88 10.5687H23ZM7.70882 22.1181C9.02882 22.6699 10.5665 23 12.1088 23C17.72 23 22.3376 18.7085 23 13.3228C12.88 13.3228 12.88 13.3228 12.88 13.3228L7.70882 22.1181Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BarIcon'
}
</script>

<style lang="scss" scoped>
  svg {
    fill: $primary-lightgrey;
  }
</style>
