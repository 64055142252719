<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 1C18.0521 1 23 5.95069 23 12C23 18.0493 18.0521 23 12 23C5.94794 23 1 18.0493 1 12C1 5.95069 5.94794 1 12 1ZM12.6597 13.2089C10.5706 13.2089 8.81133 13.8709 8.04165 14.9695C7.60183 15.5211 8.04165 16.2888 8.70137 16.2888C16.5127 16.2888 16.5127 16.2888 16.5127 16.2888C17.2824 16.2888 17.6122 15.5165 17.2824 14.9695C16.3982 13.8709 14.6389 13.2089 12.6597 13.2089ZM14.5289 9.79816C14.5289 8.69954 13.7593 7.81697 12.6597 7.81697C11.5602 7.81697 10.6805 8.69494 10.6805 9.79816C10.6805 10.7865 11.5602 11.669 12.6597 11.669C13.7593 11.669 14.5289 10.7911 14.5289 9.79816ZM7.60183 7.82156C7.60183 8.92018 7.60183 8.92018 7.60183 8.92018C6.50229 8.92018 6.50229 8.92018 6.50229 8.92018C6.50229 9.79816 6.50229 9.79816 6.50229 9.79816C7.60183 9.79816 7.60183 9.79816 7.60183 9.79816C7.60183 11.0071 7.60183 11.0071 7.60183 11.0071C8.59142 11.0071 8.59142 11.0071 8.59142 11.0071C8.59142 9.79816 8.59142 9.79816 8.59142 9.79816C9.69096 9.79816 9.69096 9.79816 9.69096 9.79816C9.69096 8.92018 9.69096 8.92018 9.69096 8.92018C8.59142 8.92018 8.59142 8.92018 8.59142 8.92018C8.59142 7.82156 8.59142 7.82156 8.59142 7.82156H7.60183Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddUserIcon'
}
</script>

<style lang="scss" scoped>
  svg {
    fill: $primary-lightgrey;
  }
</style>
