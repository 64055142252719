const QuickStart = () => import('../views/marketing/QuickStart.vue')

export const marketingRouter = [
  {
    path: '/marketing/start',
    name: 'QuickStartMarketing',
    component: QuickStart,
    meta: {
      requiresAuth: true
    }
  }
]
