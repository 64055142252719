<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.8012 20.0677C13.1002 2.68198 13.1002 2.68198 13.1002 2.68198C12.6573 1.77267 11.338 1.77267 10.8952 2.68198C1.19879 20.0677 1.19879 20.0677 1.19879 20.0677C0.649849 20.8634 1.3095 22 2.30128 22C21.6987 22 21.6987 22 21.6987 22C22.6905 22 23.3502 20.8634 22.8012 20.0677ZM11.9977 19.1584C11.2273 19.1584 10.6738 18.5901 10.6738 17.7945C10.6738 17.1125 11.2227 16.5442 11.9977 16.5442C12.7727 16.5442 13.3216 17.1125 13.3216 17.7945C13.3216 18.5901 12.7681 19.1584 11.9977 19.1584ZM13.3216 13.7026C10.5677 13.7026 10.5677 13.7026 10.5677 13.7026C10.5677 8.01942 10.5677 8.01942 10.5677 8.01942C13.3216 8.01942 13.3216 8.01942 13.3216 8.01942V13.7026Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DangerIcon'
}
</script>

<style lang="scss" scoped>
  svg {
    fill: $primary-lightgrey;
  }
</style>
